import React, { useState, useLayoutEffect } from 'react';
import {
  BrowserRouter as Router, Routes, Route, Navigate, useLocation,
} from 'react-router-dom';
import Sidebar from './Sidebar';
import Content from './Content';
import appStyles from './styles/App.module.css';

const Wrapper = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <Router>
      <Wrapper>
        <div className={`${appStyles.main} ${isMenuOpen ? appStyles.open : appStyles.closed}`}>
          <Sidebar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
          <Routes>
            <Route path="/content/:areaId/:tab?" element={<Content isMenuOpen={isMenuOpen} />} />
            <Route exact path="/" element={<Navigate replace to="/content/1" />} />
            <Route path="*" exact element={<Navigate replace to="/content/1" />} />
          </Routes>
        </div>
      </Wrapper>
    </Router>
  );
};

export default App;
