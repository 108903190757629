import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import areas from './data/areas.json';
import sidebarStyles from './styles/Sidebar.module.css';
import dtlogo from './dt_global.png';
import SupportForm from './SupportForm';

const Sidebar = ({ toggleMenu, isMenuOpen }) => {
  const [modalIsOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const areaIds = Object.keys(areas);
  const menuItems = areaIds.map((areaId) => (
    <li key={areaId} className={sidebarStyles.menuItem}>
      <NavLink
        to={`/content/${areaId}`}
        className={({ isActive }) => (isActive
          ? sidebarStyles.itemSelected
          : sidebarStyles.menuLink)}
        onClick={toggleMenu}
      >
        {areas[areaId].name}
      </NavLink>
    </li>
  ));

  return (
    <div className={sidebarStyles.sidebarContainer}>
      <div className={`${sidebarStyles.container} ${isMenuOpen ? sidebarStyles.containerOpen : sidebarStyles.containerClosed}`}>
        <ul className={sidebarStyles.menu}>
          <div className={sidebarStyles.dtLogo}>
            <img src={dtlogo} alt="DT Logo" />
            <h1>DT Global TIS3</h1>
          </div>
          {menuItems}
          {/* <li className={sidebarStyles.supportItem}>

          </li> */}
          {' '}
          {/* Add this line */}
          <SupportForm isOpen={modalIsOpen} onRequestClose={closeModal} />
          {' '}
          {/* Add this line */}
        </ul>
        <button type="button" className={sidebarStyles.supportButton} onClick={openModal}>
          Support
        </button>
      </div>
      <button className={`${sidebarStyles.toggleButton} ${isMenuOpen ? sidebarStyles.toggleButtonOpen : sidebarStyles.toggleButtonClosed}`} onClick={toggleMenu} type="button">
        {isMenuOpen ? '<' : '>'}
      </button>
    </div>
  );
};

Sidebar.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

export default Sidebar;
