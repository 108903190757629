import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import Iframe from 'react-iframe';
import powerbiLinks from './data/powerbi-links.json';
import Details from './Details';
import contentStyles from './styles/Content.module.css';
import areas from './data/areas.json';

const Content = ({ isMenuOpen }) => {
  const { areaId, tab } = useParams(); // destructure tab from useParams
  const navigate = useNavigate();
  const areaLinks = powerbiLinks[areaId];
  const [currentTab, setCurrentTab] = useState(tab || Object.keys(areaLinks)[0]);

  useEffect(() => {
    if (tab) {
      setCurrentTab(tab);
    } else {
      setCurrentTab(Object.keys(areaLinks)[0]);
    }
  }, [areaLinks, tab]);

  const handleClick = (tab) => {
    setCurrentTab(tab);
    navigate(`/content/${areaId}/${tab}`);
  };

  return (
    <main>
      <div className={`${contentStyles.header} ${isMenuOpen ? '' : contentStyles.closed}`}>
        <h2>{areas[areaId].name}</h2>
      </div>
      <nav className={`${contentStyles.topMenu} ${isMenuOpen ? '' : contentStyles.closed}`}>
        <ul>
          {Object.entries(areaLinks).map(([key]) => (
            <li key={key} className={currentTab === key ? contentStyles.activeLink : ''}>
              <button className={`${contentStyles.topMenuButton} ${contentStyles[key.slice(0, 5).toLowerCase()]}`} onClick={() => handleClick(key)} type="button">
                {key}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      <section className={`${isMenuOpen ? '' : contentStyles.openSection}`}>
        <div className={`${isMenuOpen ? contentStyles.reportContainer : contentStyles.noMargin}`}>
          <Iframe url={areaLinks[currentTab]} height="100%" />
        </div>
        {currentTab === 'Summary' && <Details id={areaId} />}
      </section>
    </main>
  );
};

Content.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default Content;
