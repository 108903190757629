import React, { useEffect, useState } from 'react';
import Iframe from 'react-iframe';
import PropTypes from 'prop-types';
import detailStyles from './styles/Details.module.css';

const Details = ({ id }) => {
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState(Object.keys(data)[0]);
  const area = id;
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  useEffect(() => {
    import(`./data/${area}.json`)
      .then((response) => {
        setData(response.default);
        setActiveTab(Object.keys(response.default)[0]);
      })
      .catch(() => {
        setData({});
      });
  }, [area]);
  return (
    Object.keys(data).length > 0
      ? (
        <section className={detailStyles.main}>
          <nav className={detailStyles.menu}>
            {Object.keys(data).map((tab) => (
              <button key={tab} onClick={() => handleTabClick(tab)} type="button" className={`${detailStyles.menuItems} ${activeTab === tab ? detailStyles.activeItem : ''}`}>{tab}</button>
            ))}
          </nav>
          <div className={detailStyles.textContainer}>
            {Object.entries(data[activeTab]).map(([key, value]) => (
              <article key={key} className={detailStyles.cssFix}>
                <div className={detailStyles.text}>
                  <h2>{key}</h2>
                  {key === 'Actor Map' ? <Iframe url={value} /> : <p className={detailStyles.par}>{value}</p> }
                </div>
              </article>
            ))}
          </div>

        </section>
      )
      : <div />

  );
};

Details.propTypes = {
  id: PropTypes.string.isRequired,
};

export default Details;
