import React, { useState } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import SupportFormStyles from './styles/SupportForm.module.css';

Modal.setAppElement('#root');

const SupportForm = ({ isOpen, onRequestClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [position, setPosition] = useState(''); // New state for position
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null); // New state for success message

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setSuccessMessage(null); // Clear success message

    if (!name || !email || !message || !position) {
      setError('All fields are required.');
      return;
    }

    if (!validateEmail(email)) {
      setError('Please enter a valid email.');
      return;
    }

    const response = await fetch('/.netlify/functions/submit', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        name, email, message, position,
      }),
    });

    if (response.ok) {
      setName('');
      setEmail('');
      setMessage('');
      setPosition('');
      setSuccessMessage('Your message has been sent.'); // Set success message
      onRequestClose();
    } else {
      setError('Failed to submit form. Please try again later.');
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={SupportFormStyles.modalContent}
      overlayClassName={SupportFormStyles.modalOverlay}
      closeTimeoutMS={200}
    >
      <button type="button" onClick={onRequestClose} className={SupportFormStyles.closeButton} label="X" />
      <form onSubmit={handleSubmit}>
        <p>Please enter your name, email, position, and support request below:</p>
        <input value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required className={SupportFormStyles.inputField} />
        <input value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required className={SupportFormStyles.inputField} />
        <input value={position} onChange={(e) => setPosition(e.target.value)} placeholder="Position" required className={SupportFormStyles.inputField} />
        <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" required className={SupportFormStyles.inputField} />
        <button type="submit" className={SupportFormStyles.submitButton}>Submit</button>
        {error && <p>{error}</p>}
        {successMessage && <p>{successMessage}</p>}
      </form>
    </Modal>
  );
};

SupportForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
};

export default SupportForm;
